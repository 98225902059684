import { render, staticRenderFns } from "./CampoSmartPos.vue?vue&type=template&id=24067c49&scoped=true&"
import script from "./CampoSmartPos.vue?vue&type=script&lang=js&"
export * from "./CampoSmartPos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24067c49",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VBtn,VCardActions,VCardText,VCol,VRow,VTextField})
